import React from 'react';
import { useEffectOnce } from 'react-use';
import { IncomingMessage } from 'http';
import { captureException, configureScope } from 'isomorphic-sentry';
import { NextPageContext } from 'next';
import { useServerContext } from 'next-server-context';

interface Props {
  statusCode: number;
  err?: any;
}

const ErrorPage = ({ statusCode, err }: Props) => {
  useEffectOnce(() => {
    ErrorPage.notifySentry(statusCode, err);

    if (err && process.env.NODE_ENV === 'development') {
      console.error(err);
    }
  });

  const serverContext = useServerContext();
  if (
    serverContext?.response &&
    (serverContext.response.statusCode === 200 || typeof serverContext.response.statusCode === 'undefined')
  ) {
    serverContext.response.statusCode = statusCode;
  }

  const message = statusCode === 404 ? ErrorPage.notFoundText : ErrorPage.errorText;

  return (
    <div className="errorContainer">
      <h1>{statusCode}</h1>
      <h2 style={{ textAlign: 'center' }}>{message}</h2>
      <br />
    </div>
  );
};

ErrorPage.errorText = 'Something Went Wrong';
ErrorPage.notFoundText = 'Page Not Found';

ErrorPage.getInitialProps = async ({ req, res, err }: Partial<NextPageContext>) => {
  let statusCode = 520;

  if (res) {
    ({ statusCode } = res);
  } else if (err?.statusCode) {
    ({ statusCode } = err);
  }
  ErrorPage.notifySentry(statusCode, err, req);
  return { statusCode, err };
};

ErrorPage.notifySentry = (statusCode: number | undefined, err: any, req?: IncomingMessage) => {
  if (statusCode !== 404) {
    // istanbul ignore next
    configureScope((scope) => {
      if (req) {
        scope.setTag(`ssr`, 'true');
        scope.setExtra(`url`, req.url);
        scope.setExtra(`headers`, req.headers);
      } else {
        scope.setTag(`ssr`, 'false');
        scope.setExtra(`statusCode`, statusCode || 'unknown');
      }
    });

    captureException(err);
  }
};

export default ErrorPage;
